import { IScanHistory } from 'app/shared/model/scan-history.model';

export interface ICertificate {
  id?: string;
  itemApiId?: string;
  itemProducerId?: string;
  itemOwnerEmailAddress?: string;
  reportedAsStolen?: boolean | null;
  smartContractAddress?: string | null;
  pictureContentType?: string | null;
  picture?: string | null;
  itemModel?: string | null;
  itemDetails?: string | null;
  itemSpecification?: string | null;
  itemOrigin?: string | null;
  itemProducerEmailAddress?: string;
  qrCodeImageContentType?: string | null;
  qrCodeImage?: string | null;
  scanners?: IScanHistory[] | null;
  initializationCode?: string | null;
  initializedItem?: boolean | null;
  lastVerified?: Date | null;
}

export const defaultValue: Readonly<ICertificate> = {
  reportedAsStolen: false,
};

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Alert } from 'reactstrap';

import { useAppSelector } from 'app/config/store';

export const AboutUs = () => {
  const account = useAppSelector(state => state.authentication.account);

  return (
    <main className="overflow-hidden">
      <header className="page header text-contrast bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="bold display-md-4 text-contrast mb-4">About us</h1>
              <p className="lead text-contrast"></p>
            </div>
          </div>
        </div>
      </header>

      <div className="position-relative">
        <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-light">
          <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
          </svg>
        </div>
      </div>
      <div className="container-fluid py-3 demo-blocks">
        <section className="section block bg-contrast">
          <div className="container py-4">
            <div className="d-flex align-items-center flex-column flex-md-row">
              <div className="text-center text-md-start">
                <h4 className="text-uppercase"></h4>
                <p className="light mb-0 text-primary lead"> Welcome to QRtificate!</p>
                <p className="text-secondary"></p>
                <p className="text-secondary"></p>
                <p className="text-secondary">We will come back with more details soon!</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default AboutUs;

import React, { useLayoutEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';

export const Logout = () => {
  const logoutUrl = useAppSelector(state => state.authentication.logoutUrl);
  const idToken = useAppSelector(state => state.authentication.idToken);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(logout());
    if (logoutUrl) {
      // if Keycloak, logoutUrl has protocol/openid-connect in it
      window.location.href = logoutUrl.includes('/protocol')
        ? logoutUrl + '?redirect_uri=' + window.location.origin
        : logoutUrl + '?id_token_hint=' + idToken + '&post_logout_redirect_uri=' + window.location.origin;
    }
  });

  return (
    <div>
      <main className="overflow-hidden">
        <header className="page header text-contrast bg-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <p className="lead text-contrast">Logged out successfully!</p>
              </div>
            </div>
          </div>
        </header>
        <div className="position-relative">
          <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-contrast">
            <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
            </svg>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Logout;

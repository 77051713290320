import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { openFile, byteSize, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities } from './certificate.reducer';
import { ICertificate } from 'app/shared/model/certificate.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const Certificate = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const certificateList = useAppSelector(state => state.certificate.entities);
  const loading = useAppSelector(state => state.certificate.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  const { match } = props;
  const isProducer = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.PRODUCER]));

  return (
    <div className="overflow-hidden">
      <header className="page header text-contrast bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="bold display-md-4 text-contrast mb-4">Here you can find all of your Certificates</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="position-relative">
        <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-contrast">
          <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
          </svg>
        </div>
      </div>
      <div className="container overflow-hidden">
        <h2 id="certificate-heading" data-cy="CertificateHeading">
          <br></br>
          <div className="d-flex justify-content-end">
            <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} /> Refresh List
            </Button>
            {isProducer ? (
              <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
                <FontAwesomeIcon icon="plus" />
                &nbsp; Create new Certificate
              </Link>
            ) : null}
          </div>
        </h2>
        <section className="section block bg-contrast">
          <div className="container py-4">
            <div className="table-responsive">
              {certificateList && certificateList.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Item Id</th>
                      <th>Owner Email Address</th>
                      <th>Reported As Stolen</th>
                      <th>Picture</th>
                      <th>Model</th>
                      <th>Specification</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {certificateList.map((certificate, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        <td>
                          <Button tag={Link} to={`${match.url}/${certificate.itemApiId}`} color="link" size="sm">
                            {certificate.itemApiId}
                          </Button>
                        </td>
                        <td>{certificate.itemOwnerEmailAddress}</td>
                        <td>{certificate.reportedAsStolen ? 'true' : 'false'}</td>
                        <td>
                          {certificate.picture ? (
                            <div>
                              {certificate.pictureContentType ? (
                                <a onClick={openFile(certificate.pictureContentType, certificate.picture)}>
                                  <img
                                    src={`data:${certificate.pictureContentType};base64,${certificate.picture}`}
                                    style={{ maxHeight: '40px' }}
                                  />
                                  &nbsp;
                                </a>
                              ) : null}
                            </div>
                          ) : null}
                        </td>
                        <td>{certificate.itemModel}</td>
                        <td>{certificate.itemSpecification}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && <div className="alert alert-warning">No Certificates found</div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Certificate;

import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Button, Row, Col, FormText } from 'reactstrap';

import { NavDropdown } from './menu-components';

const accountMenuItemsAuthenticated = (
  <>
    <MenuItem icon="lock" to="/account/password" data-cy="passwordItem">
      Password
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
      Sign out
    </MenuItem>
  </>
);

// const accountMenuItems = (
//   <li className="st-nav-section st-nav-secondary nav-item">
//     <MenuItem className="btn btn-rounded btn-outline me-3 px-3" id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
//       <i className="fas fa-sign-in-alt d-none d-md-inline me-md-0 me-lg-2"></i> <span className="d-md-none d-lg-inline">Login</span>{' '}
//     </MenuItem>
//     <MenuItem className="btn btn-rounded btn-solid px-3" icon="user-plus" to="/account/register" data-cy="register">
//       <i className="fas fa-user-plus d-none d-md-inline me-md-0 me-lg-2"></i> <span className="d-md-none d-lg-inline">Signup</span>
//     </MenuItem>
//   </li>
// );
// // const accountMenuItems = (
// //   <>
// //     <MenuItem id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
// //       Sign in
// //     </MenuItem>
// //     <MenuItem icon="user-plus" to="/account/register" data-cy="register">
// //       Register
// //     </MenuItem>
// //   </>
// // );

const withDropdown = (
  <NavDropdown icon="user" name="Account" id="account-menu" data-cy="accountMenu">
    {accountMenuItemsAuthenticated}
  </NavDropdown>
);

const accountMenuItemsAsList = (
  <li className="st-nav-section st-nav-secondary nav-item">
    <Button className="btn btn-rounded btn-outline me-3 px-3" href="/login" target="_blank" data-cy="login">
      <i className="fas fa-sign-in-alt d-none d-md-inline me-md-0 me-lg-2"></i> <span className="d-md-none d-lg-inline">Login</span>{' '}
    </Button>
    <Button className="btn btn-rounded btn-solid px-3" href="/account/register" target="_blank" data-cy="register">
      <i className="fas fa-user-plus d-none d-md-inline me-md-0 me-lg-2"></i> <span className="d-md-none d-lg-inline">Signup</span>
    </Button>
  </li>
);

const accountMenuItemsAsButton = (
  <li className="nav-item">
    <a className="btn btn-rounded btn-outline me-3 px-3" href="/login" target="_blank" data-cy="login">
      <i className="fas fa-sign-in-alt d-none d-md-inline me-md-0 me-lg-2"></i> <span className="d-md-none d-lg-inline">Login</span>{' '}
    </a>
    <a className="btn btn-rounded btn-solid px-3" href="/account/register" target="_blank" data-cy="register">
      <i className="fas fa-user-plus d-none d-md-inline me-md-0 me-lg-2"></i> <span className="d-md-none d-lg-inline">Signup</span>
    </a>
  </li>
);

export const AccountMenu = ({ isAuthenticated = false }) => <ul> {isAuthenticated ? withDropdown : accountMenuItemsAsButton} </ul>;

export default AccountMenu;

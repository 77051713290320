import React, { useState, useEffect } from 'react';
import { ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';
import { Row, Col, Alert, Button } from 'reactstrap';
import { toast } from 'react-toastify';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { handleRegister, reset } from './register.reducer';

export const RegisterPage = () => {
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  const handleValidSubmit = ({ username, email, firstPassword }) => {
    dispatch(handleRegister({ login: email, email, password: firstPassword, langKey: 'en' }));
  };

  const updatePassword = event => setPassword(event.target.value);

  const successMessage = useAppSelector(state => state.register.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage]);

  return (
    <div>
      <main className="overflow-hidden">
        <header className="page header text-contrast bg-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1 className="bold display-md-4 text-contrast mb-4">Create QRtificate account</h1>
                <p className="lead text-contrast">Creating an account will allow you to see all of your Certificates.</p>
              </div>
            </div>
          </div>
        </header>
        <div className="position-relative">
          <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-contrast">
            <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
            </svg>
          </div>
        </div>
        <Row className="justify-content-center">
          <Col md="8">
            <ValidatedForm id="register-form" onSubmit={handleValidSubmit}>
              <br></br>
              <ValidatedField
                name="email"
                label="Email"
                placeholder={'Your email'}
                type="email"
                validate={{
                  required: { value: true, message: 'Your email is required.' },
                  minLength: { value: 5, message: 'Your email is required to be at least 5 characters.' },
                  maxLength: { value: 254, message: 'Your email cannot be longer than 50 characters.' },
                  validate: v => isEmail(v) || 'Your email is invalid.',
                }}
                data-cy="email"
              />
              <ValidatedField
                name="firstPassword"
                label="New password"
                placeholder={'New password'}
                type="password"
                onChange={updatePassword}
                validate={{
                  required: { value: true, message: 'Your password is required.' },
                  minLength: { value: 4, message: 'Your password is required to be at least 4 characters.' },
                  maxLength: { value: 50, message: 'Your password cannot be longer than 50 characters.' },
                }}
                data-cy="firstPassword"
              />
              <PasswordStrengthBar password={password} />
              <ValidatedField
                name="secondPassword"
                label="New password confirmation"
                placeholder="Confirm the new password"
                type="password"
                validate={{
                  required: { value: true, message: 'Your confirmation password is required.' },
                  minLength: { value: 4, message: 'Your confirmation password is required to be at least 4 characters.' },
                  maxLength: { value: 50, message: 'Your confirmation password cannot be longer than 50 characters.' },
                  validate: v => v === password || 'The password and its confirmation do not match!',
                }}
                data-cy="secondPassword"
              />
              <Button id="register-submit" color="primary" type="submit" data-cy="submit">
                Register
              </Button>
            </ValidatedForm>
            <p>&nbsp;</p>
          </Col>
        </Row>
      </main>
    </div>
  );
};

export default RegisterPage;

import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity, ownershipVerification } from './certificate.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CertificateDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const [verificationRequested, setVerificationRequested] = useState(false);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const handleVerification = () => {
    setVerificationRequested(true);
    dispatch(ownershipVerification(certificateEntity.itemApiId));
  };

  const certificateEntity = useAppSelector(state => state.certificate.entity);
  return (
    <div className="overflow-hidden">
      <Row>
        <header className="page header text-contrast bg-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1 className="bold display-md-4 text-contrast mb-4">Certificate Details</h1>
              </div>
            </div>
          </div>
        </header>
        <div className="position-relative">
          <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-contrast">
            <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
            </svg>
          </div>
        </div>
        <div className="container overflow-hidden">
          <section className="section block bg-contrast">
            <div className="container py-4">
              <Col md="8">
                <dl className="jh-entity-details">
                  <dd>
                    {certificateEntity.picture ? (
                      <div>
                        {certificateEntity.pictureContentType ? (
                          <a onClick={openFile(certificateEntity.pictureContentType, certificateEntity.picture)}>
                            <img
                              src={`data:${certificateEntity.pictureContentType};base64,${certificateEntity.picture}`}
                              style={{ maxHeight: '100px' }}
                            />
                          </a>
                        ) : null}
                      </div>
                    ) : null}
                  </dd>
                  <dt>
                    <span id="itemApiId">ID</span>
                  </dt>
                  <dd>{certificateEntity.itemApiId}</dd>
                  <dt>
                    <span id="lastVerified">Last successful verification</span>
                  </dt>
                  <dd>{certificateEntity.lastVerified}</dd>
                  <dt>
                    <span id="itemModel">Model</span>
                  </dt>
                  <dd>{certificateEntity.itemModel}</dd>
                  <dt>
                    <span id="itemDetails">Product Details</span>
                  </dt>
                  <dd>{certificateEntity.itemDetails}</dd>
                  <dt>
                    <span id="itemSpecification">Specification</span>
                  </dt>
                  <dd>{certificateEntity.itemSpecification}</dd>
                  <dt>
                    <span id="itemOrigin">Origin</span>
                  </dt>
                  <dd>{certificateEntity.itemOrigin}</dd>
                  <dt>
                    <span id="itemOwnerEmailAddress">Owner Email Address</span>
                  </dt>
                  <dd>{certificateEntity.itemOwnerEmailAddress}</dd>
                  <dt>
                    <span id="itemProducerEmailAddress">Producer Email Address</span>
                  </dt>
                  <dd>{certificateEntity.itemProducerEmailAddress}</dd>
                  <dt>
                    <span id="lastVerified">Last Verified - Owner will receive an email from QRtificate</span>
                  </dt>
                  <dt>
                    <Button onClick={handleVerification} replace color="secondary">
                      <span className="d-none d-md-inline">Verify ownership</span>
                    </Button>
                    {verificationRequested ? (
                      <div className="response-message">
                        <div className="section-heading">
                          <i className="fas fa-check font-lg"></i>
                          <p className="response">Your message has been send, the owner should receive a verification email and confirm.</p>
                        </div>
                      </div>
                    ) : null}
                  </dt>
                  <dt>
                    <span id="reportedAsStolen">Reported As Stolen</span>
                  </dt>
                  <dd>{certificateEntity.reportedAsStolen ? 'true' : 'false'}</dd>
                  <dt>
                    <span id="qrCodeImage">Qr Code Image</span>
                  </dt>
                  <dd>
                    {certificateEntity.qrCodeImage ? (
                      <div>
                        {certificateEntity.qrCodeImageContentType ? (
                          <a onClick={openFile(certificateEntity.qrCodeImageContentType, certificateEntity.qrCodeImage)}>
                            <img
                              src={`data:${certificateEntity.qrCodeImageContentType};base64,${certificateEntity.qrCodeImage}`}
                              style={{ maxHeight: '200px' }}
                            />
                          </a>
                        ) : null}
                      </div>
                    ) : null}
                  </dd>
                </dl>
                <Button tag={Link} to="/certificate" replace color="info" data-cy="entityDetailsBackButton">
                  <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button tag={Link} to={`/certificate/${certificateEntity.itemApiId}/edit`} replace color="primary">
                  <FontAwesomeIcon icon="bicycle" /> <span className="d-none d-md-inline">Transfer Ownership - next step</span>
                </Button>
              </Col>
            </div>
          </section>
        </div>
      </Row>
    </div>
  );
};

export default CertificateDetail;

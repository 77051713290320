import React from 'react';

import { Col, Row } from 'reactstrap';

const Footer = props => (
  <footer className="site-footer section block bg-contrast">
    <div className="container py-4">
      <div className="row align-items-center">
        <div className="col-md-5 text-center text-md-left">
          <nav className="nav justify-content-center justify-content-md-start">
            <a className="nav-item nav-link" href="/about-us">
              About
            </a>{' '}
            <a className="nav-item nav-link" href="/contact">
              Contact
            </a>
          </nav>
        </div>
        <div className="col-md-2 text-center">
          <img src="content/images/logo.png" alt="" className="logo" />
        </div>
        <div className="col-md-5 text-center text-md-right">
          <p className="mt-2 mb-0 text-secondary small">© 2022 QRtificate All Rights Reserved</p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;

import React, { useState, useEffect } from 'react';

import { Navbar, NavbarBrand, NavLink, Nav, NavbarToggler, Collapse, NavItem } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand, AboutUs, Contact, HowItWorks, CertificatesSimpleLink } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu } from '../menus';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div>
      <Navbar
        color="faded"
        className={scroll ? 'st-nav navbar main-nav navigation fixed-top navbar-sticky' : 'st-nav navbar main-nav navigation fixed-top'}
        light
        expand="md"
        fixed="top"
      >
        <Brand />
        <NavbarToggler onClick={toggleMenu} className="mr-2" />
        <Collapse isOpen={menuOpen} onClick={toggleMenu} navbar>
          <Nav navbar>
            <NavItem>
              <Home />
            </NavItem>
            <NavItem>
              <HowItWorks />
            </NavItem>
            <NavItem>
              <Contact />
            </NavItem>
            <NavItem>{props.isAuthenticated && <CertificatesSimpleLink />}</NavItem>
            <NavItem>
              <AccountMenu isAuthenticated={props.isAuthenticated} />
            </NavItem>
            <NavItem>{props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}</NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;

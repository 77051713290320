import dayjs from 'dayjs';
import { ICertificate } from 'app/shared/model/certificate.model';

export interface IScanHistory {
  id?: string;
  scannedTime?: string | null;
  items?: ICertificate[] | null;
}

export const defaultValue: Readonly<IScanHistory> = {};

import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState = {
  loading: false,
  submitSuccess: false,
  submitFailure: false,
  errorMessage: null,
  successMessage: null,
};

export type ContactState = Readonly<typeof initialState>;

// Actions
export const handleContactForm = createAsyncThunk(
  'contact/submit_form',
  async (data: { sender: string; subject: string; message: string }) => axios.post<any>('api/contact-form', data),
  { serializeError: serializeAxiosError }
);

export const ContactSlice = createSlice({
  name: 'contact',
  initialState: initialState as ContactState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(handleContactForm.pending, state => {
        state.loading = true;
      })
      .addCase(handleContactForm.rejected, (state, action) => ({
        ...initialState,
        submitFailure: true,
        errorMessage: action.error.message,
      }))
      .addCase(handleContactForm.fulfilled, () => ({
        ...initialState,
        submitSuccess: true,
        successMessage: 'Contact form submitted. Thank you!',
      }));
  },
});

export const { reset } = ContactSlice.actions;

// Reducer
export default ContactSlice.reducer;

import React from 'react';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import appConfig from 'app/config/constants';

export const BrandIcon = props => (
  <li className="st-nav-section nav-item">
    <a href="#main" className="navbar-brand">
      <img src="content/images/logo.png" alt="Dashcore" className="logo logo-sticky d-inline-block d-md-none" />
      <img src="content/images/logo-light.png" alt="Dashcore" className="logo d-none d-md-inline-block" />
    </a>
  </li>
);

export const Brand = props => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIcon />
  </NavbarBrand>
);

export const Home = props => (
  <NavItem>
    <NavLink tag={Link} to="/">
      <span>Home</span>
    </NavLink>
  </NavItem>
);

export const AboutUs = props => (
  <NavItem>
    <NavLink tag={Link} to="/about-us">
      <span>About us</span>
    </NavLink>
  </NavItem>
);

export const Contact = props => (
  <NavItem>
    <NavLink tag={Link} to="/contact">
      <span>Contact</span>
    </NavLink>
  </NavItem>
);

export const FAQ = props => (
  <NavItem>
    <NavLink tag={Link} to="/faq">
      <span>FAQ</span>
    </NavLink>
  </NavItem>
);

// TODO alternative to dropdown -> will be unused
export const CertificatesSimpleLink = props => (
  <NavItem>
    <NavLink tag={Link} to="/certificate">
      <span>Certificates</span>
    </NavLink>
  </NavItem>
);

export const HowItWorks = props => (
  <NavItem>
    <NavLink tag={Link} to="/how-it-works">
      <span>How It Works</span>
    </NavLink>
  </NavItem>
);

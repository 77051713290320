import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';

import { Row, Col, Alert, Button } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { handleContactForm, reset } from '.././contact/contact.reducer';

export const Home = () => {
  const easeInProps = useSpring({
    from: { x: 0 },
    to: { x: 100 },
  });

  const dispatch = useAppDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleValidSubmit = input => {
    setIsSubmitted(true);
    dispatch(
      handleContactForm({ sender: input.sender, subject: 'Contact Request from homepage', message: 'Contact Request from homepage' })
    );
  };

  const account = useAppSelector(state => state.authentication.account);

  return (
    <Row>
      <Col className="col-anda">
        <main className="overflow-hidden">
          <header className="header online-payment-header section">
            <div id="stripes">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="container overflow-hidden bring-to-front">
              <div className="row">
                <div className="col-md-6">
                  <br></br>
                  <h1 className="text-contrast bold">
                    Ownership certified by cryptography <span className="d-block light"></span>
                  </h1>
                  <p className="lead text-contrast">
                    Using the properties of unique QR codes and email, we digitally certify your personal items
                  </p>
                </div>
                <div className="col-md-6">
                  <img src="content/images/online-payment.svg" className="img-hero img-responsive absolute-md" alt="" />
                </div>
              </div>
            </div>
          </header>
          <section id="features" className="section payment-features bg-contrast edge top-left">
            <div className="container">
              <div className="section-heading mb-6 text-center">
                <h5 className="text-primary bold small">Reliable Ownership Transfer</h5>
                <h2 className="bold">A unique interface to keep track of all your physical assets</h2>
              </div>
              <div className="row gap-y text-center text-md-start">
                <div className="col-md-4 py-4 position-relative text-center">
                  <div className="card lift-hover shadow-hover">
                    <div className="card-body p-4">
                      <div className="icon-shape mb-4">
                        <div className="shape shape-circle"></div>
                        <i className="icon fas fa-mobile fa-2x center-xy text-contrast"></i>
                      </div>
                      <h5 className="bold text-capitalize">Easy to Use </h5>
                      <p className="">
                        Using a simple QR code scan from your phone you can check all the details of a physical object and its status
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 py-4 position-relative text-center">
                  <div className="card lift-hover shadow-hover">
                    <div className="card-body p-4">
                      <div className="icon-shape mb-4">
                        <div className="shape shape-circle"></div>
                        <i className="icon fas fa-fingerprint fa-2x center-xy text-contrast"></i>
                      </div>
                      <h5 className="bold text-capitalize">Never lose Ownership</h5>
                      <p className="">
                        We certify your property rights using advanced cryptography to add an extra layer of safety since surging theft and
                        counterfeiting among physical objects is a pressing global issue
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 py-4 position-relative text-center">
                  <div className="card lift-hover shadow-hover">
                    <div className="card-body p-4">
                      <div className="icon-shape mb-4">
                        <div className="shape shape-circle"></div>
                        <i className="icon fas fa-paper-plane fa-2x center-xy text-contrast"></i>
                      </div>
                      <h5 className="bold text-capitalize">Associated with Email</h5>
                      <p className="">
                        There is no requirement to store personal information. Your email is kept anonymous, just like a regular service
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="shape-wrapper">
              <div className="shape shape-background shape-right"></div>
              <div className="shape shape-background top shape-left bg-info op-1"></div>
            </div>
            <div className="container">
              <div className="row align-items-centerpb-5">
                <div className="col-md-6">
                  <animated.div style={easeInProps}>
                    <animated.img src="content/images/register.svg" className="img-responsive w-50" alt="" />
                    <img
                      src="content/images/payments/register-click.svg"
                      className="img-responsive absolute w-50"
                      alt=""
                      style={{ bottom: '10%', left: '125px' }}
                    />
                  </animated.div>
                </div>
                <div className="col-md-6">
                  <div className="icon-xxl mb-4">
                    <div className="icon-shape">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.98 213.35" className="shape shape-xxl drop-shadow">
                        <defs>
                          <style></style>
                        </defs>
                        <title>shape-4</title>
                        <g id="layer_1" data-name="layer_1">
                          <g id="OBJECTS">
                            <path
                              className="cls-shape"
                              d="M92.2.36c58.51-4,85.65,25.71,100.68,65.52s37.29,53.51,21.92,99.33-70.9,51.88-92.59,46.52S78,192.49,51,182.07C10.79,166.56.86,146.16.06,126.06-1.07,97.63,13.92,94.45,18.73,63.28,24.51,25.93,46.43,3.47,92.2.36Z"
                            />
                          </g>
                        </g>
                      </svg>
                      <i className="icon fas fa-2x fa-user center-xy text-contrast"></i>
                    </div>
                  </div>
                  <div className="mb-5">
                    <h2 className="bold text-capitalize">register with email</h2>
                    <p className="lead text-muted">
                      Open an account using solely an email address and associate it with physical objects containing QRtificates as you
                      acquire them
                    </p>
                  </div>
                  <a href="/account/register" className="btn btn-rounded btn-primary">
                    Sign Up Free
                  </a>
                </div>
              </div>
              <div className="row align-items-center py-5">
                <div className="col-md-6 order-md-2">
                  <img src="content/images/configure.svg" className="img-responsive" alt="" />
                </div>
                <div className="col-md-6">
                  <div className="icon-xxl mb-4">
                    <div className="icon-shape">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.98 213.35" className="shape shape-xxl drop-shadow">
                        <defs>
                          <style></style>
                        </defs>
                        <title>shape-4</title>
                        <g id="layer_1" data-name="layer_1">
                          <g id="OBJECTS">
                            <path
                              className="cls-shape"
                              d="M92.2.36c58.51-4,85.65,25.71,100.68,65.52s37.29,53.51,21.92,99.33-70.9,51.88-92.59,46.52S78,192.49,51,182.07C10.79,166.56.86,146.16.06,126.06-1.07,97.63,13.92,94.45,18.73,63.28,24.51,25.93,46.43,3.47,92.2.36Z"
                            />
                          </g>
                        </g>
                      </svg>
                      <i className="icon fas fa-2x fa-users-cog center-xy text-contrast"></i>
                    </div>
                  </div>
                  <div className="mb-5">
                    <h2 className="bold text-capitalize">Verify Ownership</h2>
                    <p className="lead text-muted">
                      Scan objects marked with QRtificate and send anonymous enquiries to get real-time information about ownership and
                      traceability
                    </p>
                  </div>
                  <a href="/how-it-works" className="btn btn-rounded btn-primary">
                    Learn More
                  </a>
                </div>
              </div>
              <div className="row align-items-center py-5">
                <div className="col-md-6">
                  <img src="content/images/receive.svg" className="img-responsive" alt="" />
                </div>
                <div className="col-md-6">
                  <div className="icon-xxl mb-4">
                    <div className="icon-shape">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.98 213.35" className="shape shape-xxl drop-shadow">
                        <defs>
                          <style></style>
                        </defs>
                        <title>shape-4</title>
                        <g id="layer_1" data-name="layer_1">
                          <g id="OBJECTS">
                            <path
                              className="cls-shape"
                              d="M92.2.36c58.51-4,85.65,25.71,100.68,65.52s37.29,53.51,21.92,99.33-70.9,51.88-92.59,46.52S78,192.49,51,182.07C10.79,166.56.86,146.16.06,126.06-1.07,97.63,13.92,94.45,18.73,63.28,24.51,25.93,46.43,3.47,92.2.36Z"
                            />
                          </g>
                        </g>
                      </svg>
                      <i className="icon fas fa-2x fa-user-shield center-xy text-contrast"></i>
                    </div>
                  </div>
                  <div className="mb-5">
                    <h2 className="bold text-capitalize">Brand Certified</h2>
                    <p className="lead text-muted">
                      Capitalizing on strong partnerships with brands and harnessing the extensive potential of QR codes, we certify that
                      the product description marked with QRtificate is accurate
                    </p>
                  </div>
                  <a href="/how-it-works" className="btn btn-rounded btn-primary">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </section>
          <div className="position-relative">
            <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-darker">
              <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
              </svg>
            </div>
          </div>
          <section className="section bg-darker">
            <div className="container pb-12">
              <div className="section-heading text-center">
                <h2 className="bold text-contrast">All your certificates in one place</h2>
                <p className="lead text-muted">
                  Visualise and transfer all your assets and object specification from a comprehensive dashboard
                </p>
              </div>
              <div className="centered-screen">
                <div className="absolute-md right message text-center text-md-start" data-aos="fade-up" data-aos-delay="500">
                  <span className="bg-primary p-3 m-0 text-contrast arrow bottom small rounded">Customers administration.</span>
                </div>
                <div className="px-5 mt-5 mt-md-0">
                  <img src="content/images/customer-list.png" alt="" className="shadow-lg rounded img-responsive mx-auto op-7" />
                </div>
                <img
                  src="content/images/highlight.png"
                  alt=""
                  className="absolute shadow-lg rounded img-responsive screen-highlight"
                  data-aos="zoom-in"
                />
              </div>
            </div>
          </section>
          <div className="position-relative">
            <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-contrast">
              <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
              </svg>
            </div>
          </div>
          <section className="section">
            <div className="container pt-0 bring-to-front mt-n9">
              <div className="row">
                <div className="col-md-8 mx-md-auto">
                  <div className="card bg-dark shadow border-0">
                    <div className="card-body py-4 px-5">
                      <div className="mb-4 text-center">
                        <i data-feather="mail" data-width="36" data-height="36"></i>
                        <p className="bold lead text-contrast mb-0">Want to become a partner?</p>
                        <div className="text-muted">We&apos;ll contact you !</div>
                      </div>
                      <p className="handwritten highlight font-md mb-4">Why wait?</p>
                      <ValidatedForm
                        id="contact-form"
                        className="form"
                        data-response-message-animation="slide-in-left"
                        onSubmit={handleValidSubmit}
                      >
                        <ValidatedField
                          name="sender"
                          placeholder={'Enter your email'}
                          type="email"
                          className="form-control rounded-pill"
                          validate={{
                            required: { value: true, message: 'Your email is required.' },
                            minLength: { value: 5, message: 'Your email is required to be at least 5 characters.' },
                            maxLength: { value: 254, message: 'Your email cannot be longer than 50 characters.' },
                            validate: v => isEmail(v) || 'Your email is invalid.',
                          }}
                          data-cy="sender"
                          required
                        />
                        <button className="btn btn-rounded btn-primary rounded-pill" type="submit">
                          <i data-feather="mail" className="d-inline d-md-none"></i> <span className="d-none d-md-inline">Talk to us</span>
                        </button>
                        {isSubmitted ? (
                          <div className="response-message">
                            <div className="section-heading">
                              <i className="fas fa-check font-lg"></i>
                              <p className="font-md m-0">Thank you!</p>
                              <p className="response">Your message has been send, we will contact you as soon as possible.</p>
                            </div>
                          </div>
                        ) : null}
                      </ValidatedForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section shape-testimonials bg-light">
            <div className="shapes-container">
              <div className="shape shape-triangle">
                <div></div>
              </div>
            </div>
            <div className="container bring-to-front">
              <div className="row align-items-center">
                <div className="col-md-6 order-sm-last">
                  <div className="image-background contain" style={{ backgroundImage: 'url(content/images/map-dots.svg)' }}>
                    <div className="user-reviews" id="user-review-nav">
                      <div className="review absolute user nav-item active" style={{ left: 'calc(50% - 40px)', top: '75px' }} data-step="1">
                        <img
                          src="content/images/avatar/1.png"
                          className="img-responsive shadow rounded-circle"
                          alt="..."
                          data-aos="zoom-in"
                        />
                      </div>
                      <div className="review absolute user nav-item" style={{ left: '0', top: '140px' }} data-step="2">
                        <img
                          src="content/images/avatar/2.png"
                          className="img-responsive shadow rounded-circle"
                          alt="..."
                          data-aos="zoom-in"
                        />
                      </div>
                      <div className="review absolute user nav-item" style={{ left: 'calc(100% - 80px)', top: '300px' }} data-step="3">
                        <img
                          src="content/images/avatar/3.jpg"
                          className="img-responsive shadow rounded-circle"
                          alt="..."
                          data-aos="zoom-in"
                        />
                      </div>
                      <div className="review absolute user nav-item" style={{ left: 'calc(100% - 80px)', top: '200px' }} data-step="4">
                        <img
                          src="content/images/avatar/4.jpg"
                          className="img-responsive shadow rounded-circle"
                          alt="..."
                          data-aos="zoom-in"
                        />
                      </div>
                      <div className="review absolute user nav-item" style={{ left: '50%', top: '63%' }} data-step="5">
                        <img
                          src="content/images/avatar/5.jpg"
                          className="img-responsive shadow rounded-circle"
                          alt="..."
                          data-aos="zoom-in"
                        />
                      </div>
                      <div className="review absolute user nav-item" style={{ left: '27%', top: '30%' }} data-step="6">
                        <img
                          src="content/images/avatar/6.jpg"
                          className="img-responsive shadow rounded-circle"
                          alt="..."
                          data-aos="zoom-in"
                        />
                      </div>
                      <div className="review absolute user nav-item" style={{ left: '27%', top: '54%' }} data-step="7">
                        <img
                          src="content/images/avatar/7.jpg"
                          className="img-responsive shadow rounded-circle"
                          alt="..."
                          data-aos="zoom-in"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card shadow">
                    <div className="card-body p-5">
                      <div className="swiper-container" data-sw-navigation="#user-review-nav">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <blockquote>
                              <i className="fas fa-quote-left fa-3x"></i>
                              <p className="text-dark lead bold">
                                QRtificate is available worldwide without any geographical or technical limitations. Property transfer
                                happens simply, borderless and is certified.
                              </p>
                              <hr className="w-10 mt-5" />
                            </blockquote>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section bg-light send-and-receive">
            <div className="container bring-to-front">
              <div className="row gap-y">
                <div className="col-md-6">
                  <div
                    className="rounded bg-contrast shadow-box image-background off-left-background lift-hover p-4 ps-6 ps-md-9"
                    style={{ backgroundImage: 'url(content/images/payments/send.svg)' }}
                  >
                    <h3>Safe Transfer</h3>
                    <p className="text-secondary mb-0">
                      Each time an object changes its owner, it must be approved by both buyer and seller.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="rounded bg-contrast shadow-box image-background off-left-background lift-hover p-4 ps-6 ps-md-9"
                    style={{ backgroundImage: 'url(content/images/payments/receive.svg)' }}
                  >
                    <h3>Low Fees</h3>
                    <p className="text-secondary mb-0">
                      In order for a transfer to be successful, executing actions requires the payment of low transaction fees in order to
                      prevent abuse
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section bg-contrast edge top-left b-b">
            <div className="container pt-5">
              <div className="d-flex align-items-center flex-column flex-md-row">
                <div className="text-center text-md-start">
                  <p className="light mb-0 text-primary lead">Ready to get started?</p>
                  <h2 className="mt-0 bold">Create an account now</h2>
                </div>
                <a href="/account/register" className="btn btn-primary btn-rounded mt-3 mt-md-0 ms-md-auto">
                  Create QRtificate account
                </a>
              </div>
            </div>
          </section>
        </main>
      </Col>
    </Row>
  );
};

export default Home;

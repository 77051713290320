import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Alert } from 'reactstrap';

import { useAppSelector } from 'app/config/store';

export const HowItWorks = () => {
  const account = useAppSelector(state => state.authentication.account);

  return (
    <main className="overflow-hidden">
      <header className="page header text-contrast bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="bold display-md-4 text-contrast mb-4">How it Works</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="position-relative">
        <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-light">
          <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
          </svg>
        </div>
      </div>
      <div className="container-fluid py-3 demo-blocks"></div>

      <section className="section block bg-contrast">
        <div className="container py-4">
          <div className="d-flex align-items-center flex-column flex-md-row">
            <div className="text-center text-md-start">
              <p className="light mb-0 text-primary lead"></p>
              <p className="text-secondary ">
                <br />

                <p className="font-l">
                  {' '}
                  QRtificate provides an exclusive solution to identify and keep track of valuable physical items such as watches, apparel
                  or leather products, accomplished in a strong partnership with the brands by capitalizing on the potential of unique QR
                  codes.
                </p>
                <p className="font-l">
                  {' '}
                  Using a simple QR code scan, users have access to item information, can verify and safely transfer ownership or declare
                  items as stolen.{' '}
                </p>
                <p className="font-l">
                  {' '}
                  Each item gains a unique ID permanently stored on its surface in the form of a QR code and once acquired it becomes
                  associated with your email address.
                </p>
                <p className="font-l">
                  {' '}
                  Having the unique QR code pointing to QRtificate.com and associating it with your email ensures that your product is an
                  original.
                </p>
              </p>
              <br></br>
              <p className="font-l">
                <mark>In simple steps:</mark>
                <ul>
                  <li>QRtificate and the producers prepare all the underlying infrastructure.</li>
                  <li> Each new item has a unique QR code embedded.</li>
                  <li>Once bought, an item is scanned and the QR code is linked to your email address anonymously.</li>
                  <li>Should you decide to sell the item, you can transfer it to the new owner from our easy-to-use interface.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HowItWorks;

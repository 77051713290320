import React, { useEffect } from 'react';
import { ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';
import { Button, Alert, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import { handlePasswordResetInit, reset } from '../password-reset.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const PasswordResetInit = () => {
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  const handleValidSubmit = ({ email }) => {
    dispatch(handlePasswordResetInit(email));
  };

  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage]);

  return (
    <div>
      <main className="overflow-hidden">
        <header className="page header text-contrast bg-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1 className="bold display-md-4 text-contrast mb-4">Reset your password</h1>
              </div>
            </div>
          </div>
        </header>
        <div className="position-relative">
          <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-contrast">
            <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
            </svg>
          </div>
        </div>
        <Row className="justify-content-center">
          <Col md="8">
            <Alert color="warning">
              <p>Enter the email address you used to register</p>
            </Alert>
            <ValidatedForm onSubmit={handleValidSubmit}>
              <ValidatedField
                name="email"
                label="Email"
                placeholder={'Your email'}
                type="email"
                validate={{
                  required: { value: true, message: 'Your email is required.' },
                  minLength: { value: 5, message: 'Your email is required to be at least 5 characters.' },
                  maxLength: { value: 254, message: 'Your email cannot be longer than 50 characters.' },
                  validate: v => isEmail(v) || 'Your email is invalid.',
                }}
                data-cy="emailResetPassword"
              />
              <Button color="primary" type="submit" data-cy="submit">
                Reset password
              </Button>
            </ValidatedForm>
          </Col>
        </Row>
      </main>
    </div>
  );
};

export default PasswordResetInit;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Alert } from 'reactstrap';

import { useAppSelector } from 'app/config/store';

export const FAQ = () => {
  const account = useAppSelector(state => state.authentication.account);

  return (
    <main className="overflow-hidden">
      <header className="page header text-contrast bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="bold display-md-4 text-contrast mb-4">FAQ&#39;s</h1>
              <p className="lead text-contrast">Help your users to hel discover and answer their questions in an easy way</p>
            </div>
          </div>
        </div>
      </header>
      <div className="position-relative">
        <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-light">
          <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
          </svg>
        </div>
      </div>
      <div className="container-fluid py-3 demo-blocks">
        <section className="section block bg-contrast">
          <div className="container py-4">
            <div className="row">
              <div className="col-md-4">
                <h2>
                  Do you have <span className="bold">questions?</span>
                </h2>
                <p className="lead">Not sure how this template can help you? Wonder why you need to buy our theme?</p>
                <p className="text-muted">
                  Here are the answers to some of the most common questions we hear from our appreciated customers
                </p>
              </div>
              <div className="col-md-8">
                <div className="accordion accordion-clean" id="faqs-accordion">
                  <div className="card mb-3">
                    <div className="card-header">
                      <a href="#" className="card-title btn" data-bs-toggle="collapse" data-bs-target="#v1-q1">
                        <i className="fas fa-angle-down angle"></i> What does the package include?
                      </a>
                    </div>
                    <div id="v1-q1" className="collapse show" data-bs-parent="#faqs-accordion">
                      <div className="card-body">
                        When you buy Dashcore, you get all you see in the demo but the images. We include SASS files for styling, complete
                        JS files with comments, all HTML variations. Besides we include all mobile PSD mockups.
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-header">
                      <a href="#" className="card-title btn collapsed" data-bs-toggle="collapse" data-bs-target="#v1-q2">
                        <i className="fas fa-angle-down angle"></i> What is the typical response time for a support question?
                      </a>
                    </div>
                    <div id="v1-q2" className="collapse" data-bs-parent="#faqs-accordion">
                      <div className="card-body">
                        Since you report us a support question we try to make our best to find out what is going on, depending on the case
                        it could take more or les time, however a standard time could be minutes or hours.
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-header">
                      <a href="#" className="card-title btn collapsed" data-bs-toggle="collapse" data-bs-target="#v1-q3">
                        <i className="fas fa-angle-down angle"></i> What do I need to know to customize this template?
                      </a>
                    </div>
                    <div id="v1-q3" className="collapse" data-bs-parent="#faqs-accordion">
                      <div className="card-body">
                        Our documentation give you all you need to customize your copy. However you will need some basic web design
                        knowledge (HTML, Javascript and CSS)
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-header">
                      <a href="#" className="card-title btn collapsed" data-bs-toggle="collapse" data-bs-target="#v1-q4">
                        <i className="fas fa-angle-down angle"></i> Can I suggest a new feature?
                      </a>
                    </div>
                    <div id="v1-q4" className="collapse" data-bs-parent="#faqs-accordion">
                      <div className="card-body">
                        Definitely <span className="bold">Yes</span>, you can contact us to let us know your needs. If your suggestion
                        represents any value to both we can include it as a part of the theme or you can request a custom build by an extra
                        cost. Please note it could take some time in order for the feature to be implemented.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default FAQ;

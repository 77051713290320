import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, ValidatedField, ValidatedForm, ValidatedBlobField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IScanHistory } from 'app/shared/model/scan-history.model';
import { getEntities as getScanHistories } from 'app/entities/scan-history/scan-history.reducer';
import { getEntity, updateEntity, createEntity, reset } from './certificate.reducer';
import { ICertificate } from 'app/shared/model/certificate.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CertificateUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const scanHistories = useAppSelector(state => state.scanHistory.entities);
  const certificateEntity = useAppSelector(state => state.certificate.entity);
  const loading = useAppSelector(state => state.certificate.loading);
  const updating = useAppSelector(state => state.certificate.updating);
  const updateSuccess = useAppSelector(state => state.certificate.updateSuccess);
  const currentUser = useAppSelector(state => state.authentication.account);

  const isInit = !certificateEntity.initializedItem && !isNew;

  const handleClose = id => {
    if (isNew) {
      props.history.push({
        pathname: '/certificate',
      });
    } else if (isInit) {
      props.history.push({
        pathname: '/certificate',
      });
    } else {
      props.history.push({
        pathname: '/certificate/' + id + '/checkout',
        state: { newOwner: certificateEntity.itemOwnerEmailAddress },
      });
    }
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getScanHistories({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose(props.match.params.id);
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...certificateEntity,
      ...values,
      initializationCode: values.initializationCode,
    };

    entity.initializationCode = values.initializationCode;

    const entityHack = { entityI: entity, numberCertificates: values.certNumber };

    if (isNew) {
      dispatch(createEntity(entityHack));
    } else {
      dispatch(updateEntity(entityHack.entityI));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...certificateEntity,
        };

  return (
    <div className="overflow-hidden">
      <header className="page header text-contrast bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {isNew ? (
                <h1 className="bold display-md-4 text-contrast mb-4">Create new Certificates</h1>
              ) : (
                <h1 className="bold display-md-4 text-contrast mb-4">Add new Owner Email address</h1>
              )}
            </div>
          </div>
        </div>
      </header>
      <div className="position-relative">
        <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-contrast">
          <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
          </svg>
        </div>
      </div>
      <div className="container overflow-hidden">
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                <br></br>
                <ValidatedBlobField
                  labelHidden
                  label="Picture"
                  id="certificate-picture"
                  name="picture"
                  data-cy="picture"
                  isImage
                  accept="image/*"
                />
                {!isNew ? (
                  <ValidatedField name="id" required hidden readOnly id="certificate-id" label="ID" validate={{ required: true }} />
                ) : null}
                <br></br>
                {!isNew ? (
                  <ValidatedField name="itemApiId" required readOnly id="certificate-itemApiId" label="ID" validate={{ required: true }} />
                ) : null}
                <ValidatedField label="Model" id="certificate-itemModel" name="itemModel" data-cy="itemModel" type="text" />
                <ValidatedField
                  label="Item Details"
                  id="certificate-itemDetails"
                  name="itemDetails"
                  data-cy="itemDetails"
                  type="textarea"
                />
                <ValidatedField
                  label="Specification"
                  id="certificate-itemSpecification"
                  name="itemSpecification"
                  data-cy="itemSpecification"
                  type="textarea"
                />
                <ValidatedField label="Origin" id="certificate-itemOrigin" name="itemOrigin" data-cy="itemOrigin" type="text" />
                {isNew ? (
                  <ValidatedField
                    label="Owner Email Address"
                    id="certificate-itemOwnerEmailAddress"
                    name="itemOwnerEmailAddress"
                    data-cy="itemOwnerEmailAddress"
                    type="text"
                    required
                    readOnly
                    value={currentUser.email}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                      pattern: {
                        value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
                        message: "This field should follow pattern for '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+..",
                      },
                    }}
                  />
                ) : (
                  <ValidatedField
                    label="New Owner Email Address"
                    id="certificate-itemOwnerEmailAddress"
                    name="itemOwnerEmailAddress"
                    data-cy="itemOwnerEmailAddress"
                    type="text"
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                      pattern: {
                        value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
                        message: "This field should follow pattern for '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+..",
                      },
                    }}
                  />
                )}
                <ValidatedField
                  required
                  readOnly
                  label="Producer Email Address"
                  id="certificate-itemProducerEmailAddress"
                  name="itemProducerEmailAddress"
                  data-cy="itemProducerEmailAddress"
                  type="text"
                  value={currentUser.email}
                  validate={{
                    required: { value: true, message: 'This field is required.' },
                    pattern: {
                      value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
                      message: "This field should follow pattern for '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+..",
                    },
                  }}
                />
                {isInit ? (
                  <ValidatedField
                    label="Initialization Code"
                    id="certificate-initializationCode"
                    name="initializationCode"
                    data-cy="initializationCode"
                    type="text"
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  />
                ) : null}
                {!isNew ? (
                  <ValidatedField
                    name="smartContractAddress"
                    required
                    readOnly
                    id="certificate-smartContractAddress"
                    label="Smart Contract Address"
                    validate={{ required: true }}
                  />
                ) : null}
                <ValidatedField
                  required
                  hidden
                  readOnly
                  label="Item Producer Id"
                  id="certificate-itemProducerId"
                  name="itemProducerId"
                  data-cy="itemProducerId"
                  type="text"
                  value={currentUser.email}
                  validate={{
                    required: { value: true, message: 'This field is required.' },
                  }}
                />
                {!isNew ? (
                  <ValidatedField
                    name="qrCodeImage"
                    hidden
                    required
                    readOnly
                    id="certificate-qrCodeImage"
                    label="Qr Code Image"
                    validate={{ required: true }}
                  />
                ) : null}
                <ValidatedField
                  label="Reported As Stolen"
                  id="certificate-reportedAsStolen"
                  name="reportedAsStolen"
                  data-cy="reportedAsStolen"
                  check
                  type="checkbox"
                />
                {isNew ? (
                  <ValidatedField
                    name="certNumber"
                    id="certificate-certNumber"
                    label="Number of new Certificates - maximum 50"
                    type="number"
                  />
                ) : null}
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/certificate" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="angle-double-right" />
                  &nbsp; Continue
                </Button>
              </ValidatedForm>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CertificateUpdate;

import 'react-toastify/dist/ReactToastify.css';
import 'app/config/dayjs.ts';

import React, { useEffect } from 'react';
import { Card } from 'reactstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader';
import { Helmet } from 'react-helmet';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppCookieConsent from 'app/shared/cookie-consent/cookie-consent';
import AppRoutes from 'app/routes';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const paddingTop = '60px';
  return (
    <Router basename={baseHref}>
      <meta charSet="utf-8" />
      <meta name="description" content="" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <link rel="apple-touch-icon" href="apple-touch-icon.png" />
      <link rel="icon" href="favicon.ico" />
      <link href="https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,700,900" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Caveat" rel="stylesheet" />
      <link rel="stylesheet" href="content/css/fontawesome.css" />
      <link rel="stylesheet" href="content/css/aos.css" />
      <link rel="stylesheet" href="content/css/magnific-popup.css" />
      <link rel="stylesheet" href="content/css/odometer-theme-minimal.css" />
      <link rel="stylesheet" href="content/css/prism-okaidia.css" />
      <link rel="stylesheet" href="content/css/simplebar.css" />
      <link rel="stylesheet" href="content/css/smart_wizard_all.css" />
      <link rel="stylesheet" href="content/css/swiper-bundle.css" />
      <link rel="stylesheet" href="content/css/dashcore.css" />
      <link rel="stylesheet" href="content/css/rtl.css" />
      <link rel="stylesheet" href="content/css/demo.css" />
      <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
      <ErrorBoundary>
        <Header
          isAuthenticated={isAuthenticated}
          isAdmin={isAdmin}
          ribbonEnv={ribbonEnv}
          isInProduction={isInProduction}
          isOpenAPIEnabled={isOpenAPIEnabled}
        />
      </ErrorBoundary>
      <Card>
        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>
      </Card>
      <Footer />
    </Router>
  );
};

export default hot(module)(App);

import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { openFile, byteSize, ValidatedForm } from 'react-jhipster';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity, redirectToStripeCheckout } from './certificate.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CertificateCheckout = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const redirectUrl = useAppSelector(state => state.certificate.redirectUrl);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  interface MyLocationTypes {
    newOwner: string;
  }
  const paramsObject = useLocation<MyLocationTypes>();
  const newOwnerHistory = paramsObject.state.newOwner;
  const certificateEntity = useAppSelector(state => state.certificate.entity);

  const saveEntity = values => {
    const certImm = { ...certificateEntity, itemOwnerEmailAddress: newOwnerHistory };

    dispatch(redirectToStripeCheckout(certImm));
  };

  useEffect(() => {
    if (redirectUrl !== '') {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  return (
    <div className="overflow-hidden">
      <Row>
        <header className="page header text-contrast bg-primary">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1 className="bold display-md-4 text-contrast mb-4">Certificate Transfer Summary</h1>
                <p className="lead text-contrast">Make sure the new Owner email is correct.</p>
              </div>
            </div>
          </div>
        </header>
        <div className="position-relative">
          <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-contrast">
            <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
            </svg>
          </div>
        </div>
        <div className="container overflow-hidden">
          <section className="section block bg-contrast">
            <div className="container py-4">
              <Col md="8">
                <ValidatedForm onSubmit={saveEntity}>
                  <dl className="jh-entity-details">
                    <br></br>
                    <dt>
                      <span id="id">Ownership Transfer summary: </span>
                    </dt>
                    <dd>
                      You will transfer Item with Id {certificateEntity.itemApiId} to a new owner with email address: {newOwnerHistory}
                    </dd>
                    <dd>
                      {certificateEntity.picture ? (
                        <div>
                          {certificateEntity.pictureContentType ? (
                            <a onClick={openFile(certificateEntity.pictureContentType, certificateEntity.picture)}>
                              <img
                                src={`data:${certificateEntity.pictureContentType};base64,${certificateEntity.picture}`}
                                style={{ maxHeight: '100px' }}
                              />
                            </a>
                          ) : null}
                        </div>
                      ) : null}
                    </dd>
                    <dt>
                      <span id="itemApiId">ID</span>
                    </dt>
                    <dd>{certificateEntity.itemApiId}</dd>
                    <dt>
                      <span id="itemModel">Model</span>
                    </dt>
                    <dd>{certificateEntity.itemModel}</dd>
                    <dt>
                      <span id="itemDetails">Product Details</span>
                    </dt>
                    <dd>{certificateEntity.itemDetails}</dd>
                    <dt>
                      <span id="itemSpecification">Specification</span>
                    </dt>
                    <dd>{certificateEntity.itemSpecification}</dd>
                    <dt>
                      <span id="itemOrigin">Origin</span>
                    </dt>
                    <dt>
                      <span id="itemOwnerEmailAddress">Current Owner Email Address - will change after payment</span>
                    </dt>
                    <dd>{certificateEntity.itemOwnerEmailAddress}</dd>
                    <dt>
                      <span id="itemProducerEmailAddress">Producer Email Address</span>
                    </dt>
                    <dd>{certificateEntity.itemProducerEmailAddress}</dd>
                    <dt>
                      <span id="smartContractAddress">Smart Contract Address - on Avalanche blockchain</span>
                    </dt>
                    <dd>{certificateEntity.smartContractAddress}</dd>
                    <dt>
                      <span id="reportedAsStolen">Reported As Stolen</span>
                    </dt>
                    <dd>{certificateEntity.reportedAsStolen ? 'true' : 'false'}</dd>
                    <dt>
                      <span id="qrCodeImage">Qr Code</span>
                    </dt>
                    <dd>
                      {certificateEntity.qrCodeImage ? (
                        <div>
                          {certificateEntity.qrCodeImageContentType ? (
                            <a onClick={openFile(certificateEntity.qrCodeImageContentType, certificateEntity.qrCodeImage)}>
                              <img
                                src={`data:${certificateEntity.qrCodeImageContentType};base64,${certificateEntity.qrCodeImage}`}
                                style={{ maxHeight: '100px' }}
                              />
                            </a>
                          ) : null}
                        </div>
                      ) : null}
                    </dd>
                  </dl>
                  <Button tag={Link} to="/certificate" replace color="info" data-cy="entityDetailsBackButton">
                    <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
                  </Button>
                  &nbsp;
                  <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit">
                    <FontAwesomeIcon icon="credit-card" />
                    &nbsp; CHECKOUT
                  </Button>
                </ValidatedForm>
              </Col>
            </div>
          </section>
        </div>
      </Row>
    </div>
  );
};

export default CertificateCheckout;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';

import { Row, Col, Alert, Button } from 'reactstrap';

import { handleContactForm, reset } from './contact.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const Contact = () => {
  const account = useAppSelector(state => state.authentication.account);
  const dispatch = useAppDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleValidSubmit = input => {
    setIsSubmitted(true);
    dispatch(handleContactForm({ sender: input.sender, subject: input.subject, message: input.message }));
  };

  return (
    <main className="overflow-hidden">
      <header className="page header text-contrast bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="bold display-md-4 text-contrast mb-4">Contact</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="position-relative">
        <div className="shape-divider shape-divider-bottom shape-divider-fluid-x text-light">
          <svg viewBox="0 0 2880 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"></path>
          </svg>
        </div>
      </div>
      <div className="container-fluid py-3 demo-blocks">
        <section className="section block bg-contrast">
          <div className="container py-4">
            <div className="row gap-y">
              <div className="col-12 col-md-6">
                <div className="section-heading">
                  <p className="text-uppercase">Get in touch</p>
                  <h2 className="font-md bold">We&apos;d like to hear from you</h2>
                </div>
                <p className="lead mb-5">Don&apos;t hesitate to get in contact with us no matter your request. We are here to help you.</p>
                <ul className="list-unstyled list-icon">
                  <li>
                    <i className="fas fa-envelope text-primary"></i>
                    <p>
                      <a href="mailto:contact@qrtificate.com">contact@qrtificate.com</a>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                <div id="emailHelp" className="small form-text text-secondary mt-0 mb-2 italic">
                  We&apos;ll never share your email with anyone else.
                </div>
                <ValidatedForm id="contact-form" onSubmit={handleValidSubmit}>
                  <ValidatedField
                    name="sender"
                    label="Your email"
                    placeholder={'Valid Email'}
                    type="email"
                    validate={{
                      required: { value: true, message: 'Your email is required.' },
                      minLength: { value: 5, message: 'Your email is required to be at least 5 characters.' },
                      maxLength: { value: 254, message: 'Your email cannot be longer than 50 characters.' },
                      validate: v => isEmail(v) || 'Your email is invalid.',
                    }}
                    data-cy="sender"
                    required
                  />
                  <ValidatedField type="text" name="subject" label="Subject" id="contact_subject" placeholder="Subject" required />
                  <ValidatedField
                    name="message"
                    id="contact_message"
                    placeholder="What do you want to let us know?"
                    rows={8}
                    type="textarea"
                    required
                  />
                  <Button color="primary" type="submit" data-cy="submit">
                    Send Message
                  </Button>
                </ValidatedForm>
                {isSubmitted ? (
                  <div className="response-message">
                    <div className="section-heading">
                      <i className="fas fa-check font-lg"></i>
                      <p className="font-md m-0">Thank you!</p>
                      <p className="response">Your message has been send, we will contact you as soon as possible.</p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Contact;
